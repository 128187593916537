import { readState } from "@/__main__/app-state.mjs";
import { MAX_TIME } from "@/__main__/constants.mjs";
import getData from "@/__main__/get-data.mjs";
import { mergeLocalWithApi } from "@/app/util.mjs";
import CsgoMatch from "@/data-models/csgo-match.mjs";
import CsgoMatches from "@/data-models/csgo-matches.mjs";
import CsgoPlayer from "@/data-models/csgo-player.mjs";
import CsgoPlayerMapStats from "@/data-models/csgo-player-map-stats.mjs";
import CsgoPlayerStats from "@/data-models/csgo-player-stats.mjs";
import CsgoPlayerWeaponStats from "@/data-models/csgo-player-weapon-stats.mjs";
import * as API from "@/game-csgo/api.mjs";
import {
  DEFAULT_MATCHES_LIMIT,
  GAME_MODES,
  gameModes,
} from "@/game-csgo/constants.mjs";
import { addMatchesToMatchlists, getModeParams } from "@/game-csgo/utils.mjs";
import { devLog } from "@/util/dev.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";

async function fetchData([profileId, tab], searchParam, state) {
  const isOwnAccount = Boolean(
    readState.settings.loggedInAccounts.csgo[profileId],
  );
  const expiryTime = !isOwnAccount ? null : MAX_TIME;

  const promises = [];

  const profile = await getData(
    API.getPlayer({ steamId: profileId }),
    CsgoPlayer,
    ["csgo", "profiles", profileId],
    {
      shouldFetchIfPathExists: !state.isUpdate,
      expiryTime,
      mergeFn: mergeLocalWithApi,
    },
  ).catch(() => {
    devLog(`CS2 player does not exist for steam id ${profileId}`);
  });

  if (!profile) return;

  const paramsForStats = {
    mode: GAME_MODES.ALL.id,
  };

  for (const key in paramsForStats) {
    const value = searchParam.get(key);
    if (value) paramsForStats[key] = value;
  }

  const queryParams = getModeParams(paramsForStats.mode);

  if (!tab) {
    getData(
      API.getPlayerStats({ steamId: profileId, ...queryParams }),
      CsgoPlayerStats,
      ["csgo", "playerStats", profileId, paramsForStats.mode],
      {
        shouldFetchIfPathExists: !state.isUpdate,
        expiryTime,
      },
    );

    const gameModeIdsWithRanked = gameModes.filter(
      (i) => i.key?.startsWith("0_1_"), // 0_1_ is essentially all ranked game modes
    );
    for (const gameMode of gameModeIdsWithRanked) {
      getData(
        API.getPlayerStats({
          steamId: profileId,
          ...getModeParams(gameMode.id),
        }),
        CsgoPlayerStats,
        ["csgo", "playerStats", profileId, gameMode.id],
        {
          shouldFetchIfPathExists: !state.isUpdate,
          expiryTime,
        },
      );
    }
  }

  if (!tab || tab === "maps") {
    const getPlayerMapStats = getData(
      API.getPlayerMapStats({ steamId: profileId, ...queryParams }),
      CsgoPlayerMapStats,
      ["csgo", "playerMapStats", profileId, paramsForStats.mode],
      {
        shouldFetchIfPathExists: !state.isUpdate,
        expiryTime,
      },
    );

    promises.push(getPlayerMapStats);
  }

  if (!tab || tab === "weapons") {
    const getPlayerWeaponStats = getData(
      API.getPlayerWeaponStats({ steamId: profileId, ...queryParams }),
      CsgoPlayerWeaponStats,
      ["csgo", "playerWeaponStats", profileId, paramsForStats.mode],
      {
        shouldFetchIfPathExists: !state.isUpdate,
        expiryTime,
      },
    );

    promises.push(getPlayerWeaponStats);
  }

  await Promise.all(promises);

  // Matchlist + match fetching
  if (!state || !state.transient?.visibleMatches) {
    const matchlist = await getData(
      API.getMatchlist({
        ...(paramsForStats.mode === GAME_MODES.ALL.id ? {} : queryParams),
        ...(state?.page ? { offset: state?.page * DEFAULT_MATCHES_LIMIT } : {}),
        steamId: profileId,
      }),
      CsgoMatches,
      undefined,
    );
    orderArrayBy(matchlist, "gameStartedAt", "desc");
    const matchesToFetch = [];
    for (
      let i = 0;
      i < Math.min(matchlist.length, DEFAULT_MATCHES_LIMIT);
      i += 1
    ) {
      matchesToFetch.push(
        getData(
          API.getMatch({ csgoMatchId: matchlist[i].matchId }),
          CsgoMatch,
          ["csgo", "matches", matchlist[i].matchId],
          {
            shouldFetchIfPathExists: false,
            expiryTime: expiryTime,
            mergeFn: mergeLocalWithApi,
          },
        ),
      );
    }
    const result = await Promise.all(matchesToFetch);
    addMatchesToMatchlists(profileId, result);
    return;
  }

  const matchlistByMode =
    readState.csgo.matchlists[profileId]?.[paramsForStats.mode] ?? [];
  const visibleMatches = matchlistByMode.filter(
    ({ matchId }) => matchId && state.transient?.visibleMatches?.[matchId],
  );
  const result = await Promise.all(
    visibleMatches.map((i) =>
      getData(
        API.getMatch({ csgoMatchId: i.matchId }),
        CsgoMatch,
        ["csgo", "matches", i.matchId],
        {
          shouldFetchIfPathExists: false,
          expiryTime: expiryTime,
          mergeFn: mergeLocalWithApi,
        },
      ),
    ),
  );
  addMatchesToMatchlists(profileId, result);
}

export default fetchData;
